export const NoticeList = [
  {
    label: "兑币机余币不足",
    subLabel: "订单实际出币数＜购币数（适用于兑币机）",
    value: "LACK_COIN",
    checked: false,
  },
  {
    label: "每日经营收入",
    subLabel: "设备经营收入数据",
    value: "DAILY_INCOME",
    checked: false,
  },
  {
    label: "设备离线",
    subLabel:
      "设备工作时段离线时间超过<span style='color: #1980ff'>30</span>分钟",
    value: "DEVICE_OFFLINE",
    checked: false,
  },
  {
    label: "礼品库存不足",
    subLabel:
      "礼品库存低于<span style='color: #1980ff'>10</span>个（适用于娃娃机）",
    value: "LACK_GIFT",
    checked: false,
  },
];
