<template>
  <Page is-header>
    <div
      class="template-content bg-white u-flex u-row-between u-p-l-12 u-p-r-12 u-col-top"
    >
      <div>
        <div class="u-flex">
          <div class="template-name">{{ packageInfo.templateName }}</div>
          <div v-if="packageInfo.defaultValue" class="template-default">
            默认
          </div>
        </div>
        <div class="u-content-color u-m-t-16">套餐类型：x元y币</div>
      </div>
      <van-button
        type="default"
        round
        class="template-edit-btn"
        @click="templateEdit"
        v-if="useAuth('B030102')"
        >编辑</van-button
      >
    </div>
    <div class="machine-type u-flex u-p-t-12 u-p-b-12 u-p-l-12">
      <van-button
        :type="deviceType === 1 ? 'primary' : 'default'"
        class="btn-baby"
        @click="changeMachine(1)"
        >游乐设备</van-button
      >
      <van-button
        :type="deviceType === 2 ? 'primary' : 'default'"
        class="btn-exchange u-m-l-12"
        @click="changeMachine(2)"
        >兑币设备</van-button
      >
    </div>
    <div class="deposit u-flex u-row-between content bg-white u-p-t-12">
      <span class="u-flex u-flex-col u-col-top">
        <span>购币优惠</span>
        <span class="u-font-12 u-tips-color u-m-t-10">
          适用线上支付后出币，现金购币套餐在设备端设置
        </span>
      </span>
      <van-button
        type="primary"
        class="deposit-btn"
        v-if="useAuth('B030102')"
        round
        @click="addDeposit(1)"
        >添加</van-button
      >
    </div>
    <div class="bg-white u-p-t-12">
      <van-cell-group>
        <template v-for="(item, index) in templateItemList" :key="item.id">
          <div
            v-if="item.deviceType === deviceType"
            class="btn-container u-flex u-row-between"
          >
            <div class="btn-title u-flex">
              <div>{{ item.amount }}元={{ item.virtualValue }}币</div>
              <div
                v-if="item.recommendValue && useAuth('B030102')"
                class="btn-recommend u-m-l-12"
              >
                推荐
              </div>
            </div>
            <div class="btn-content u-flex">
              <van-button
                v-if="!item.recommendValue && useAuth('B030102')"
                type="default"
                class="btn-list"
                round
                @click="setRecomment(item.id)"
                >推荐</van-button
              >
              <van-button
                type="default"
                class="btn-list"
                round
                @click="editTemplateItem(index, 1)"
                v-if="useAuth('B030102')"
                >编辑</van-button
              >
              <van-button
                v-if="!item.standardValue && useAuth('B030102')"
                type="default"
                class="btn-list"
                round
                @click="handleDelete(item.id)"
                >删除</van-button
              >
              <van-switch
                v-if="
                  deviceType === 2 && item.standardValue && useAuth('B030102')
                "
                :model-value="item.state"
                size="18px"
                class="u-m-l-8"
                @update:model-value="onUpdateValue"
                @click="chooseSwitch(item.id)"
              />
            </div>
          </div>
        </template>
      </van-cell-group>
    </div>
    <!-- 弹窗 -->
    <van-dialog
      v-model:show="addStatus"
      title="设置套餐"
      confirm-button-color="#027AFF"
      show-cancel-button
      :before-close="beforeClose"
    >
      <div class="dialog-box">
        <div class="u-flex u-row-center">
          <div class="price-input van-hairline--surround u-radius-2">
            <input
              v-model="editTmpInfo.amount"
              v-numberPrice
              type="number"
              inputmode="decimal"
              step="0.01"
              placeholder="请输入"
              :disabled="editTmpInfo.standardValue"
            />
          </div>
          <span>元</span>
          <div class="price-input van-hairline--surround u-radius-2">
            <input
              v-model="editTmpInfo.virtualValue"
              :maxlength="5"
              type="number"
              pattern="\d*"
              placeholder="请输入"
            />
          </div>
          <span>币</span>
        </div>
      </div>
    </van-dialog>
    <van-dialog
      v-model:show="templateDialog"
      title="编辑套餐"
      confirm-button-color="#027AFF"
      show-cancel-button
      :before-close="submitTmp"
    >
      <div class="content u-p-l-22 u-p-r-30">
        <van-field
          v-model="info.templateName"
          name="templateName"
          label="套餐名称"
          placeholder="请输入套餐名称"
          maxlength="10"
          input-align="right"
          class="van-none-p"
          label-class="van-field-title"
          :rules="[{ required: true, message: '请输入套餐名称' }]"
        />
        <van-field
          name="isDefault"
          label="默认套餐"
          input-align="right"
          class="van-none-p"
          label-class="van-field-title"
        >
          <template #input>
            <van-switch
              v-model="info.defaultValue"
              size="20"
              :disabled="packageInfo.defaultValue"
            />
          </template>
        </van-field>
      </div>
    </van-dialog>
  </Page>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeUpdate,
  onActivated,
  inject,
} from "vue";
import { useRoute } from "vue-router";
import { Dialog, Toast } from "vant";
import { useActions, useState } from "@/hooks/useVuex";
export default defineComponent({
  name: "PackageEdit",
  setup() {
    const route = useRoute();
    const state = reactive({
      packageInfo: {
        templateName: "",
        defaultValue: true,
      },
      info: { templateName: "", defaultValue: true },
      templateItemList: [],
      rechargeItemList: [],
      editTmpInfo: { amount: "", virtualValue: "", state: 0 },
    });
    const templateDialog = ref(false);
    const consumptionType = ref(0);
    const checkboxRefs = ref([]);
    const addStatus = ref(false);
    // 区分机器类型 1为娃娃机，2为兑币机
    const deviceType = ref(1);
    const templateItemId = ref("");
    const useAuth = inject("useAuth");
    // 存放编辑时候的模板项id
    const {
      insertTemplateItem,
      getTemplateDetail,
      deleteTmpItem,
      updateTmpItemActions,
      updateTmpStatusActions,
      updateRecommend,
    } = useActions("goodsPackage", [
      "insertTemplateItem",
      "getTemplateDetail",
      "deleteTmpItem",
      "updateTmpItemActions",
      "updateTmpStatusActions",
      "updateRecommend",
    ]);
    const { templateDetail } = useState("goodsPackage", ["templateDetail"]);
    onActivated(async () => {
      reset();
      if (route.query.id) {
        getDetail();
      }
    });
    // 重置数据
    const reset = () => {
      state.templateItemList = [];
      state.rechargeItemList = [];
      state.packageInfo = {
        templateName: "",
        defaultValue: true,
      };
      deviceType.value = 1;
    };
    // 详情
    const getDetail = async () => {
      try {
        await getTemplateDetail(route.query.id);
        state.packageInfo = {
          templateName: templateDetail.value.templateName,
          // 需要将1 0 转换成true false

          defaultValue: templateDetail.value.defaultValue ? true : false,
        };
        state.info = { ...state.packageInfo };
        state.templateItemList = templateDetail.value.templateItemList.map(
          (item) => ({
            ...item,
            amount: (item.amount / 100).toFixed(2),
            state: item.state ? true : false,
          })
        );
        state.rechargeItemList = templateDetail.value.rechargeItemList.map(
          (item) => ({
            ...item,
            amount: (item.amount / 100).toFixed(2),
            state: item.state ? true : false,
          })
        );
      } catch (error) {
        Toast(error.message);
      }
    };
    // 設置推荐
    const setRecomment = (templateItemId) => {
      Dialog.confirm({
        title: "确定设为推荐?",
        message: "推荐套餐消费者购买会自动选中",
        "confirm-button-color": "#027AFF",
      }).then(async () => {
        // 当前有且只有一个为推荐
        try {
          await updateRecommend({ templateItemId });

          Toast("更新成功");
          getDetail();
        } catch (error) {
          console.log(error);
          Toast(error.message);
        }
      });
    };
    // 刪除
    const handleDelete = (templateItemId) => {
      Dialog.confirm({
        message: "确定删除？",
        "confirm-button-color": "#027AFF",
      }).then(async () => {
        // on confirm
        try {
          await deleteTmpItem({
            templateItemId: templateItemId,
          });

          Toast("删除成功");
          getDetail();
        } catch (error) {
          Toast(error.message);
        }
      });
    };
    // 添加重置套餐
    const addDeposit = (tradeType) => {
      if (
        (tradeType === 0 &&
          state.templateItemList.filter(
            (item) => item.deviceType === deviceType.value
          ).length === 10) ||
        (tradeType === 1 &&
          state.rechargeItemList.filter(
            (item) => item.deviceType === deviceType.value
          ).length === 10)
      ) {
        Toast("最多只能添加10个套餐");
        return;
      }
      consumptionType.value = tradeType;
      addStatus.value = true;
      state.editTmpInfo = {
        amount: "",
        virtualValue: "",
        state: 0,
      };
    };
    // 编辑模板Item
    const editTemplateItem = (index, tradeType) => {
      // 打开弹窗
      if (tradeType === 1) {
        state.editTmpInfo = { ...state.templateItemList[index] };
      } else if (tradeType === 2) {
        state.editTmpInfo = { ...state.rechargeItemList[index] };
      }
      addStatus.value = true;
    };
    const updateTmpItem = async (data) => {
      try {
        await updateTmpItemActions({
          ...data,
        });

        Toast(data.templateItemId ? "编辑成功" : "新增成功");
        getDetail();
      } catch (error) {
        Toast(error.message);
      }
    };
    // 在关闭前判断数据的完整性
    const beforeClose = (action) => {
      return new Promise((resolve) => {
        if (action === "confirm") {
          const virtualValue = state.editTmpInfo.virtualValue;
          if (!state.editTmpInfo.amount || !virtualValue) {
            Toast("请填写完整");
            resolve(false);
          } else {
            // 新增或者修改充值优惠
            // 修改
            const { id, amount, virtualValue } = state.editTmpInfo;
            let params = {
              amount: amount * 100,
              virtualValue: Number(virtualValue),
              itemDesc: `${amount}元${virtualValue}币`,
            };
            if (state.editTmpInfo.id) {
              params.templateItemId = id;
            } else {
              console.log(route.query.id, "id");
              params = {
                ...params,
                itemDesc: `${amount}元${virtualValue}币`,
                templateId: route.query.id,
                deviceType: deviceType.value,
                consumptionType: 0,
              };
            }
            updateTmpItem(params);
            resolve(true);
          }
        } else {
          resolve(true);
        }
      });
    };
    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    });
    // 编辑 新增商户套餐
    const submitTmp = async (action) => {
      return new Promise((resolve) => {
        console.log(action);
        if (action === "confirm") {
          if (!state.info.templateName) {
            Toast("请输入套餐名称");
            resolve(false);
          }
          handleUpdateState(resolve);
        } else {
          resolve(true);
        }
      });
    };
    const changeMachine = (type) => {
      deviceType.value = type;
    };
    // 更新状态
    const updateTmpStatus = async (state) => {
      try {
        await updateTmpStatusActions(state);

        Toast("更新成功");
        getDetail();
      } catch (error) {
        Toast(error.message);
      }
    };
    // 更新template模板接口
    const handleUpdateState = async (resolve) => {
      try {
        await insertTemplateItem({
          templateId: route.query.id,
          ...state.info,
          defaultValue: state.info.defaultValue ? 1 : 0,
        });
        resolve(true);
        Toast("编辑成功");
        getDetail();
        templateDialog.value = false;
      } catch (error) {
        console.log(error);
      }
    };
    // 更新禁用状态
    const onUpdateValue = (newValue) => {
      Dialog.confirm({
        message: newValue
          ? "启用后，手机扫码可选择该套餐购币~"
          : "禁用后，手机扫码无法选择该套餐，不影响现金购币~",
        "confirm-button-color": "#027AFF",
        confirmButtonText: newValue ? "确定启动" : "确定禁用",
      }).then(async () => {
        console.log(newValue, "newValue");
        try {
          const res = await updateTmpStatus({
            templateItemId: templateItemId.value,
            state: newValue ? 1 : 0,
          });
          console.log(res);
          // if (code === 200) {
          //   Toast(newValue ? "禁用成功" : "启用成功");
          //   getDetail();
          // }
        } catch (error) {
          Toast(error.message);
        }
      });
    };
    const chooseSwitch = (id) => {
      templateItemId.value = id;
    };
    // 编辑套餐
    const templateEdit = () => {
      templateDialog.value = true;
      state.info = { ...state.packageInfo };
    };
    return {
      // state
      ...toRefs(state),
      addStatus,
      templateDialog,
      deviceType,
      //event
      setRecomment,
      handleDelete,
      addDeposit,
      beforeClose,
      submitTmp,
      editTemplateItem,
      changeMachine,
      onUpdateValue,
      chooseSwitch,
      templateEdit,
      useAuth,
    };
  },
});
</script>

<style lang="scss" scoped>
.template-content {
  padding: 12px 24px;
  .template-edit-btn {
    width: 56px;
    height: 24px;
    font-size: 12px;
    white-space: nowrap;
  }
  .template-name {
    font-size: 18px;
    color: #333;
  }
  .template-default {
    width: 32px;
    height: 16px;
    font-size: 12px;
    background: #f0f2f5;
    border-radius: 2px;
    color: #999;
    line-height: 16px;
    text-align: center;
    margin-left: 8px;
    line-height: normal;
  }
}
.machine-type {
  .btn-baby,
  .btn-exchange {
    width: 100px;
    height: 32px;
    font-size: 14px;
    padding: 0 12px;
  }
}
.deposit {
  .deposit-btn {
    width: 56px;
    height: 24px;
    font-size: 12px;
    white-space: nowrap;
  }
}

.btn-container {
  height: 46px;
  position: relative;
  .btn-list {
    width: 60px;
    height: 24px;
    font-size: 12px;
    color: #333;
    margin-left: 8px;
    white-space: nowrap;
  }
  .btn-title {
    position: relative;
  }
  .btn-recommend {
    width: 38px;
    height: 19px;
    background: #fa2d19;
    border-radius: 10px 10px 10px 0px;
    font-size: 11px;
    color: #fff;
    text-align: center;
    line-height: 19px;
    position: absolute;
    right: -38px;
    top: -14px;
  }
}

.dialog-box {
  padding: 20px;
  .price-input {
    width: 100px;
    height: 36px;
    overflow: hidden;
    margin: 0 8px;
    input {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0 12px;
    }
  }
}
.device-type-content {
  height: 50%;
}
.header-item {
  width: 33.3%;
  flex: 1;
  height: 40px;
  line-height: 40px;
}
.template-btn {
  position: fixed;
  width: 100%;
  bottom: 20px;
}
</style>
