<template>
  <div
    class="u-flex u-p-12 van-hairline--bottom bg-white"
    @click="handleSelectChange"
  >
    <div v-if="type === 1" class="u-flex u-m-r-12">
      <van-icon
        class-prefix="iconfont"
        :name="modelValue ? 'success' : 'circle'"
        size="24"
        :color="modelValue ? '#027AFF' : '#666666'"
      />
    </div>
    <div class="u-flex u-flex-1 u-flex-column u-col-top">
      <p class="u-main-color u-font-16 u-m-b-8 u-line-height-22">
        {{ info.label }}
      </p>
      <p
        class="u-tips-color u-font-14 u-line-height-20"
        v-html="info.subLabel"
      />
    </div>
    <div v-if="type === 2" class="u-flex u-row-right u-m-l-12">
      <van-switch
        :model-value="modelValue"
        size="24"
        @update:model-value="handleChangeValue"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Dialog } from "vant";
export default defineComponent({
  name: "NoticeItem",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const handleSelectChange = () => {
      if (props.type !== 1) return false;
      emit("update:modelValue", !props.modelValue);
    };
    const handleChangeValue = (value) => {
      Dialog.confirm({
        title: "温馨提醒",
        message: `确认${value ? "开启" : "关闭"}${props.info.label}预警通知`,
        confirmButtonColor: "#1980ff",
      }).then(() => {
        emit("update:modelValue", value);
      });
    };
    return {
      handleChangeValue,
      handleSelectChange,
    };
  },
});
</script>
