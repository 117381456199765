<template>
  <Page is-header>
    <ul v-if="roleMonitorList.length" class="u-p-12">
      <li
        class="bg-white u-p-12 u-m-b-12 u-radius-6"
        v-for="role in roleMonitorList"
        :key="role.roleId"
      >
        <p class="u-m-b-8">{{ role.roleName || "--" }}</p>
        <div
          class="u-flex u-col-top u-font-14 u-line-height-20 u-m-b-8 u-content-color"
        >
          <p style="width: 120px">推送通知类型：</p>
          <p class="u-flex-1">{{ role.desc || "--" }}</p>
        </div>
        <div
          class="u-flex u-row-right"
          @click.stop="handleShowToast(role.roleId)"
        >
          <van-button
            round
            style="width: 88px"
            type="primary"
            size="small"
            :disabled="role.roleId === '2'"
            @click.stop="handleUpdateConfig(role)"
          >
            编辑
          </van-button>
        </div>
      </li>
    </ul>
    <van-empty v-else image="search" description="暂无角色配置信息" />

    <Pick
      v-model:visible="isShowNoticePop"
      header
      title="选择通知类型"
      @confirm="handleSelectConfirm"
    >
      <div>
        <template v-for="item in NoticeLists" :key="item.value">
          <NoticeItem v-model="item.checked" :type="1" :info="item" />
        </template>
      </div>
    </Pick>
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, onActivated } from "vue";
import Pick from "@/components/Pick.vue";
import NoticeItem from "./components/NoticeItem.vue";
import { NoticeList } from "./enums";
import { cloneDeep } from "lodash";
import {
  getMonitorConfigByRoleApi,
  setMonitorConfigByRoleApi,
} from "@/api/monitor";
import { Toast } from "vant";
export default defineComponent({
  name: "NoticeSetting",
  components: { Pick, NoticeItem },
  setup() {
    const isShowNoticePop = ref(false);
    const NoticeLists = reactive(cloneDeep(NoticeList));
    const roleMonitorList = ref([]);
    const roleId = ref("");

    onActivated(() => {
      roleId.value = "";
      handleGetConfig();
    });

    const handleGetConfig = async () => {
      try {
        const { content, code } = await getMonitorConfigByRoleApi();
        if (code === 200) {
          console.log("content", content);
          roleMonitorList.value = content || [];
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const formatValueToEcho = (content = []) => {
      NoticeLists.forEach((item) => {
        if (content.includes(item.value)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    };

    const handleUpdateConfig = (role) => {
      roleId.value = role.roleId;
      formatValueToEcho(role.content);
      isShowNoticePop.value = true;
    };

    const handleSelectConfirm = async () => {
      const content = NoticeLists.filter((item) => item.checked).map(
        (key) => key.value
      );
      try {
        const { code } = await setMonitorConfigByRoleApi({
          roleId: roleId.value,
          content,
        });
        if (code === 200) {
          Toast("编辑成功");
          handleGetConfig();
        }
      } catch (error) {
        console.log("error", error);
      }
      isShowNoticePop.value = false;
    };

    const handleShowToast = (id) => {
      if (id === "2") return Toast("运营商管理员的通知类型不可编辑");
    };
    return {
      NoticeLists,
      isShowNoticePop,
      handleSelectConfirm,
      roleMonitorList,
      handleUpdateConfig,
      handleShowToast,
    };
  },
});
</script>
