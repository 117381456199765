<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <div class="search">
        <Search v-model="searchKey" @onSearch="onSearch" />
      </div>
    </van-sticky>
    <div class="u-p-l-12 u-p-r-12 u-p-b-80">
      <div v-if="templateList.length">
        <template v-for="item in templateList" :key="item.id">
          <div
            class="package-content bg-white u-p-l-12 u-p-r-12 u-m-t-12"
            @click.stop="handleEdit(item.id)"
          >
            <div class="u-flex u-row-between">
              <div>
                <div class="package-title u-flex">
                  <div>{{ item.templateName }}</div>
                  <span v-if="item.defaultValue">默认</span>
                </div>
                <div class="package-sub">
                  <span>适用设备：</span>
                  <span>游乐设备、兑币设备</span>
                </div>
                <div class="package-sub">
                  <span>套餐类型：</span> <span>X元Y币</span>
                </div>
              </div>
              <van-icon
                class="arrow-left"
                name="arrow-left"
                color="#979797"
                size="20"
              />
            </div>
            <div class="u-flex u-row-right">
              <van-button
                type="default"
                plain
                round
                class="operation-btn"
                @click.stop="handleEdit(item.id)"
                >编辑</van-button
              >
              <van-button
                v-if="useAuth('B030103')"
                type="default"
                plain
                round
                class="operation-btn u-m-l-12"
                @click.stop="handleSetPoint(item)"
                >设置场地</van-button
              >
            </div>
          </div>
        </template>
      </div>

      <van-empty v-else image="search" description="暂无数据" />
    </div>

    <div v-if="useAuth('B030102')" class="bottom-operation u-p-l-12 u-p-r-12">
      <van-button type="primary" round @click="handleAdd">新增+</van-button>
    </div>

    <!-- 选择场地 -->
    <PopBottomList
      v-model:visible="isShowPick"
      pop-type="site"
      :default-value="currentSites"
      :multiple="true"
      :at-least-one="false"
      @save="handleSavePoint"
    />
  </Page>
</template>

<script>
import { Toast } from "vant";
import { defineComponent, ref, onActivated, inject } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Search from "@/components/Search.vue";
import PopBottomList from "@/components/PopBottomList.vue";
import { useActions, useState } from "@/hooks/useVuex";
export default defineComponent({
  name: "PackageList",
  components: {
    Search,
    PopBottomList,
  },
  setup() {
    const router = useRouter();
    const isShowPick = ref(false);
    const searchKey = ref("");
    const templateId = ref("");
    const isLoading = ref(false);
    const useAuth = inject("useAuth");
    const currentSites = ref([]);
    // 获取列表
    const { getTemplateList, setTemplateSit } = useActions("goodsPackage", [
      "getTemplateList",
      "setTemplateSit",
    ]);
    // 列表内容
    const { templateList } = useState("goodsPackage", ["templateList"]);
    onBeforeRouteLeave((to, from) => {
      console.log(from, to, "----------");
    });
    onActivated(() => {
      searchKey.value = "";
      getTemplateList();
    });

    // 设置场地
    const handleSavePoint = async (selected) => {
      if (isLoading.value) return;
      isLoading.value = true;
      let siteIds = selected.map((item) => item.siteId);
      await setTemplateSit({
        templateId: templateId.value,
        siteIds,
      });
      isLoading.value = false;
      getTemplateList();
      Toast("设置成功");
      isShowPick.value = false;
    };

    // 新增
    const handleAdd = () => {
      router.push({
        name: "PackageTemplate",
      });
    };
    // 编辑
    const handleEdit = (id) => {
      router.push({
        name: "PackageDetail",
        query: {
          id,
        },
      });
    };
    const handleSetPoint = (item) => {
      templateId.value = item.id;
      currentSites.value = item.siteIds || [];
      isShowPick.value = !isShowPick.value;
    };
    const onSearch = () => {
      getTemplateList(searchKey.value);
    };
    return {
      templateList,
      isShowPick,
      searchKey,
      currentSites,
      // event
      handleAdd,
      handleEdit,
      handleSavePoint,
      handleSetPoint,
      onSearch,
      useAuth,
    };
  },
});
</script>

<style lang="scss" scoped>
.package-content {
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  .package-title {
    line-height: 20px;
    margin-bottom: 8px;
    div {
      color: #666;
    }
    span:last-child {
      display: inline-block;
      background: #f0f2f5;
      color: #999999;
      width: 32px;
      height: 16px;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
    }
  }
  .package-sub {
    line-height: 20px;
    margin-bottom: 8px;
    color: #666;
  }
  .arrow-left {
    transform: rotate(180deg);
  }
  .operation-btn {
    width: 80px;
    height: 32px;
    font-size: 12px;
    padding: 0 12px;
    color: #373738;
    white-space: nowrap;
  }
  .package-device {
    position: absolute;
    top: 0;
    right: 0;
    background: #999999;
    opacity: 0.8;
    color: #fff;
    font-size: 12px;
    width: 34px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 4px;
  }
}
.bottom-operation {
  position: fixed;
  bottom: 12px;
  width: 100%;
}
</style>
