<template>
  <Page is-header>
    <div class="bg-white u-flex u-row-between u-p-12 u-col-top">
      <div>
        <div class="u-flex">
          <div class="u-font-18">{{ templateDetail.templateName }}</div>
          <Tag
            v-if="templateDetail.defaultValue"
            class="u-m-l-10"
            text="默认"
            bg="#f0f2f5"
            color="#999999"
          />
        </div>
        <div class="u-content-color u-m-t-16">套餐类型：x元y币</div>
      </div>
      <van-button
        type="default"
        round
        class="template-edit-btn"
        @click="handleToTemplateEdit"
        v-if="useAuth('B030102')"
      >
        编辑
      </van-button>
    </div>
    <div class="u-flex u-p-t-12 u-p-b-12 u-p-l-12">
      <van-button
        class="tab-button u-font-14 u-m-r-10"
        :type="deviceType === 1 ? 'primary' : 'default'"
        @click="onChangeDeviceType(1)"
      >
        游乐设备
      </van-button>
      <van-button
        class="tab-button"
        :type="deviceType === 2 ? 'primary' : 'default'"
        @click="onChangeDeviceType(2)"
      >
        兑币设备
      </van-button>
    </div>
    <!-- 购币优惠 -->
    <TemplateCard
      title="购币优惠"
      tips="适用线上支付后出币，现金购币套餐在设备端设置"
      :device-type="deviceType"
      :trade-type="0"
      v-model:template-list="templateBuyList"
      @recomment="setRecomment"
      @delete="handleDelete"
      @add="handleAddTemplate"
      @change="handleChangeTemplateStatus"
    />
    <div class="u-m-t-12" />
    <!-- 储币优惠 -->
    <TemplateCard
      v-if="deviceType === 2"
      title="储币优惠"
      tips="适用线上支付后发放虚拟币给用户，需提币"
      :device-type="deviceType"
      :trade-type="1"
      v-model:template-list="templateStoreList"
      @recomment="setRecomment"
      @delete="handleDelete"
      @add="handleAddTemplate"
      @change="handleChangeTemplateStatus"
    />
    <!-- 编辑套餐 -->
    <van-dialog
      v-model:show="isShowTemplateEdit"
      title="编辑套餐"
      confirm-button-color="#027AFF"
      show-cancel-button
      :before-close="handleSubmitEditTemplate"
    >
      <div class="content u-p-l-22 u-p-r-30">
        <van-field
          v-model="editTemplateInfo.templateName"
          name="templateName"
          label="套餐名称"
          placeholder="请输入套餐名称"
          maxlength="10"
          input-align="right"
          class="van-none-p"
          label-class="van-field-title"
        />
        <van-field
          name="isDefault"
          label="默认套餐"
          input-align="right"
          class="van-none-p"
          label-class="van-field-title"
        >
          <template #input>
            <van-switch
              v-model="editTemplateInfo.defaultValue"
              size="20"
              :disabled="templateDetail.defaultValue"
            />
          </template>
        </van-field>
      </div>
    </van-dialog>
    <!-- 新增编辑优惠 -->
    <!-- 弹窗 -->
    <van-dialog
      v-model:show="isShowEditDiscount"
      title="设置套餐"
      confirm-button-color="#027AFF"
      show-cancel-button
      :before-close="handleConfirmEditDiscount"
    >
      <div class="u-p-12">
        <div class="u-flex u-row-center">
          <div class="price-input van-hairline--surround u-radius-2">
            <input
              v-model="editDiscountInfo.amount"
              v-numberPrice
              type="number"
              inputmode="decimal"
              step="0.01"
              placeholder="请输入"
              :disabled="editDiscountInfo.standardValue"
            />
          </div>
          <span>元</span>
          <div class="price-input van-hairline--surround u-radius-2">
            <input
              v-model="editDiscountInfo.virtualValue"
              :maxlength="5"
              type="number"
              pattern="\d*"
              placeholder="请输入"
            />
          </div>
          <span>币</span>
        </div>
      </div>
    </van-dialog>
  </Page>
</template>
<script>
import { defineComponent, ref, reactive, onActivated, inject } from "vue";
import { useRoute } from "vue-router";
import { useActions, useState } from "@/hooks/useVuex";
import { amountFmt } from "@/utils/format";
import Tag from "@/components/Tag.vue";
import TemplateCard from "./components/TemplateCard.vue";
import { Dialog, Toast } from "vant";
export default defineComponent({
  name: "PackageDetail",
  components: { Tag, TemplateCard },
  setup() {
    const route = useRoute();
    const templateId = ref("");
    const useAuth = inject("useAuth");
    const isShowTemplateEdit = ref(false); // 编辑套餐弹窗
    const isShowEditDiscount = ref(false); // 编辑优惠弹窗
    const templateBuyList = ref([]); // 购币套餐列表
    const templateStoreList = ref([]); // 储币套餐列表
    const editDiscountInfo = reactive({
      amount: "",
      virtualValue: "",
      state: 0,
      consumptionType: 0, // 0: 购币 1：储币
      id: "",
    });
    const deviceType = ref(1);
    const editTemplateInfo = reactive({
      templateName: "",
      defaultValue: false,
    });
    const { templateDetail } = useState("goodsPackage", ["templateDetail"]);
    const {
      getTemplateDetail,
      insertTemplateItem,
      deleteTmpItem,
      updateTmpItemActions,
      updateTmpStatusActions,
      updateRecommend,
    } = useActions("goodsPackage", [
      "insertTemplateItem",
      "getTemplateDetail",
      "deleteTmpItem",
      "updateTmpItemActions",
      "updateTmpStatusActions",
      "updateRecommend",
    ]);

    onActivated(async () => {
      templateId.value = route.query.id || "";
      templateId.value && (await getTemplateDetailInfo());
    });

    const onChangeTemplateList = () => {
      templateBuyList.value = (templateDetail.value.templateItemList || [])
        .filter((t) => t.deviceType === deviceType.value)
        .map((i) => {
          return {
            ...i,
            amount: amountFmt(i.amount),
            state: Boolean(i.state),
          };
        });
      templateStoreList.value = (templateDetail.value.rechargeItemList || [])
        .filter((t) => t.deviceType === deviceType.value)
        .map((i) => {
          return {
            ...i,
            amount: amountFmt(i.amount),
            state: Boolean(i.state),
          };
        });
    };

    const getTemplateDetailInfo = async () => {
      try {
        await getTemplateDetail(templateId.value);
        onChangeTemplateList();
      } catch (error) {
        console.log("error", error);
      }
    };

    const handleToTemplateEdit = () => {
      editTemplateInfo.templateName = templateDetail.value.templateName;
      editTemplateInfo.defaultValue = Boolean(
        templateDetail.value.defaultValue
      );
      isShowTemplateEdit.value = true;
    };

    const onChangeDeviceType = (type) => {
      deviceType.value = type;
      onChangeTemplateList();
    };
    // 設置推荐
    const setRecomment = (templateItemId) => {
      Dialog.confirm({
        title: "确定设为推荐?",
        message: "推荐套餐消费者购买会自动选中",
        "confirm-button-color": "#027AFF",
      }).then(async () => {
        // 当前有且只有一个为推荐
        try {
          await updateRecommend({ templateItemId });

          Toast("更新成功");
          getTemplateDetailInfo();
        } catch (error) {
          console.log(error);
          Toast(error.message);
        }
      });
    };
    // 刪除
    const handleDelete = (templateItemId) => {
      Dialog.confirm({
        message: "确定删除？",
        "confirm-button-color": "#027AFF",
      }).then(async () => {
        // on confirm
        try {
          await deleteTmpItem({
            templateItemId: templateItemId,
          });

          Toast("删除成功");
          getTemplateDetailInfo();
        } catch (error) {
          Toast(error.message);
        }
      });
    };
    // 编辑套餐
    const handleSubmitEditTemplate = async (action) => {
      if (action === "confirm") {
        try {
          if (!editTemplateInfo.templateName) {
            Toast("请输入套餐名称");
            return Promise.resolve(false);
          }
          await insertTemplateItem({
            templateId: templateId.value,
            templateName: editTemplateInfo.templateName,
            defaultValue: Number(editTemplateInfo.defaultValue),
          });
          Toast("编辑成功");
          getTemplateDetailInfo();
          return Promise.resolve(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        return Promise.resolve(true);
      }
    };
    const handleAddTemplate = (consumptionType, discountItem) => {
      console.log("consumptionType", consumptionType);
      console.log("discountItem", discountItem);
      editDiscountInfo.amount = discountItem ? discountItem.amount : "";
      editDiscountInfo.virtualValue = discountItem
        ? discountItem.virtualValue
        : "";
      editDiscountInfo.state = discountItem ? discountItem.state : 0;
      editDiscountInfo.consumptionType = consumptionType;
      editDiscountInfo.id = discountItem ? discountItem.id : "";
      isShowEditDiscount.value = true;
    };
    // 新增编辑优惠
    const handleConfirmEditDiscount = async (action) => {
      if (action === "confirm") {
        try {
          let params = {
            amount: editDiscountInfo.amount * 100,
            virtualValue: Number(editDiscountInfo.virtualValue),
            itemDesc: `${editDiscountInfo.amount}元${editDiscountInfo.virtualValue}币`,
            templateId: templateId.value,
            deviceType: deviceType.value,
            consumptionType: editDiscountInfo.consumptionType,
          };
          if (editDiscountInfo.id) params.templateItemId = editDiscountInfo.id;
          await updateTmpItemActions(params);
          Toast(editDiscountInfo.id ? "编辑成功" : "新增成功");
          getTemplateDetailInfo();
          return Promise.resolve(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        return Promise.resolve(true);
      }
    };
    // 改变开关
    const handleChangeTemplateStatus = (value, templateItem) => {
      Dialog.confirm({
        message: value
          ? "启用后，手机扫码可选择该套餐购币~"
          : "禁用后，手机扫码无法选择该套餐，不影响现金购币~",
        "confirm-button-color": "#027AFF",
        confirmButtonText: value ? "确定启动" : "确定禁用",
        async beforeClose(action) {
          if (action === "confirm") {
            // 这里肯定要判断点的哪个按钮，之前小明写的点取消也会调用接口，不知道测试咋测的
            try {
              await updateTmpStatusActions({
                templateItemId: templateItem.id,
                state: Number(value),
              });
              Toast("更新成功");
              templateItem.state = !templateItem.state; // 只是改变开关的状态，不需要调用详情接口了
              return Promise.resolve(true);
            } catch (error) {
              console.log("error", error);
              return Promise.resolve(false);
            }
          } else {
            return Promise.resolve(true);
          }
        },
      });
    };
    return {
      useAuth,
      deviceType,
      editTemplateInfo,
      editDiscountInfo,
      isShowTemplateEdit,
      isShowEditDiscount,
      templateDetail,
      templateBuyList,
      templateStoreList,
      onChangeDeviceType,
      setRecomment,
      handleDelete,
      handleAddTemplate,
      handleToTemplateEdit,
      handleSubmitEditTemplate,
      handleConfirmEditDiscount,
      handleChangeTemplateStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
.template-edit-btn {
  width: 56px;
  height: 24px;
  font-size: 12px;
  white-space: nowrap;
}
.tab-button {
  width: 100px;
  height: 32px;
}
.price-input {
  width: 100px;
  height: 36px;
  overflow: hidden;
  margin: 0 8px;
  input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 12px;
  }
}
</style>
