<template>
  <div class="bg-white u-radius-4 u-p-12 u-m-t-12">
    <div class="u-flex u-row-between u-m-b-12 u-line-height-20">
      <!-- <i class="iconfont iconfont-qidongjilu u-font-20" /> -->
      <div class="u-flex u-flex-1">
        <van-icon
          size="20"
          class-prefix="iconfont"
          :name="PayTypeIcons[orderInfo.mainPayType].icon"
          :color="PayTypeIcons[orderInfo.mainPayType].color"
        />
        <span class="u-font-15 u-m-l-8">{{ orderInfo.tradeNo }}</span>
      </div>
      <van-icon size="18" name="arrow" color="#979797" />
    </div>
    <p class="u-flex u-m-b-12">
      <span class="u-font-15 u-content-color u-m-r-10">
        {{ orderInfo.goodsPackageName }}
      </span>
      <Tag
        :text="payStatusFmt(orderInfo.orderState)"
        :bg="
          orderInfo.orderState === 'PAY_SUCCESS'
            ? '#f0f2f5'
            : 'rgba(245, 108, 108, 0.1)'
        "
        :color="orderInfo.orderState === 'PAY_SUCCESS' ? '#999999' : '#f56c6c'"
      />
      <Tag
        class="u-m-l-10"
        :text="['', '购币', '储币'][orderInfo.tradeType]"
        bg="#f0f2f5"
        color="#999999"
      />
    </p>
    <p class="u-font-15 u-flex u-row-between">
      <span class="u-tips-color">{{ orderInfo.gmtCreate }}</span>
      <span class="u-color-red">￥{{ amountFmt(orderInfo.mchAmount) }}</span>
    </p>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { amountFmt, payStatusFmt } from "@/utils/format";
import Tag from "@/components/Tag.vue";
import { PayTypeIcons } from "@/hooks/useConst";
export default defineComponent({
  name: "OrderInfo",
  props: ["orderInfo"],
  components: { Tag },
  setup() {
    const state = reactive({});
    return {
      PayTypeIcons,
      ...toRefs(state),
      amountFmt,
      payStatusFmt,
    };
  },
});
</script>
