<template>
  <div class="bg-white">
    <div class="u-flex u-row-between u-p-12 van-hairline--bottom">
      <span class="u-flex u-flex-col u-col-top">
        <span>{{ title }}</span>
        <span class="u-font-12 u-tips-color u-m-t-10">
          {{ tips }}
        </span>
      </span>
      <van-button
        type="primary"
        class="template-edit-btn"
        v-if="useAuth('B030102')"
        round
        @click="handleAddTemplate"
      >
        添加
      </van-button>
    </div>
    <div class="">
      <div
        v-for="template in templateList"
        :key="template.id"
        class="u-p-12 u-flex u-row-between van-hairline--bottom"
      >
        <div class="template-name">
          <span>{{ template.amount }}元={{ template.virtualValue }}币</span>
          <span
            v-if="template.recommendValue && useAuth('B030102')"
            class="recommend-text u-m-l-12"
          >
            推荐
          </span>
        </div>
        <div class="u-flex">
          <van-button
            v-if="!template.recommendValue && useAuth('B030102')"
            type="default"
            class="btn-list"
            round
            @click="setRecomment(template.id)"
          >
            推荐
          </van-button>
          <van-button
            type="default"
            class="btn-list"
            round
            @click="editTemplateItem(template)"
            v-if="useAuth('B030102')"
          >
            编辑
          </van-button>
          <van-button
            v-if="!template.standardValue && useAuth('B030102')"
            type="danger"
            class="btn-list"
            round
            @click="handleDelete(template.id)"
          >
            删除
          </van-button>
          <van-switch
            v-if="
              deviceType === 2 && template.standardValue && useAuth('B030102')
            "
            :model-value="template.state"
            size="18px"
            class="u-m-l-8"
            @update:model-value="
              (newValue) => onChangeSwitch(newValue, template)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { defineComponent, inject } from "vue";
export default defineComponent({
  name: "TemplateCard",
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    tips: {
      type: String,
      default: "",
    },
    tradeType: {
      type: Number,
      default: 0,
    },
    deviceType: {
      type: Number,
      default: 0,
    },
  },
  emits: ["recomment", "delete"],
  setup(props, { emit }) {
    const useAuth = inject("useAuth");
    // 設置推荐
    const setRecomment = (templateItemId) => {
      emit("recomment", templateItemId);
    };
    // 刪除
    const handleDelete = (templateItemId) => {
      emit("delete", templateItemId);
    };
    const handleAddTemplate = () => {
      console.log("123", 123);
      if (props.templateList.length >= 10) return Toast("最多只能添加10个套餐");
      emit("add", props.tradeType, null);
    };
    const editTemplateItem = (item) => {
      emit("add", props.tradeType, item);
    };
    const onChangeSwitch = (value, templateItem) => {
      console.log("value, templateItemId", value, templateItem);
      emit("change", value, templateItem);
    };
    return {
      useAuth,
      setRecomment,
      handleDelete,
      handleAddTemplate,
      editTemplateItem,
      onChangeSwitch,
    };
  },
});
</script>
<style lang="scss" scoped>
.template-edit-btn {
  width: 56px;
  height: 24px;
  font-size: 12px;
  white-space: nowrap;
}
.template-name {
  position: relative;
  .recommend-text {
    width: 38px;
    height: 19px;
    background: #fa2d19;
    border-radius: 10px 10px 10px 0px;
    font-size: 11px;
    color: #fff;
    text-align: center;
    line-height: 19px;
    position: absolute;
    right: -38px;
    top: -14px;
  }
}
.btn-list {
  width: 60px;
  height: 24px;
  font-size: 12px;
  margin-left: 10px;
}
</style>
