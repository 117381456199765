<template>
  <Page is-header>
    <!-- 设备信息 -->
    <div class="device-info u-p-12 bg-white">
      <div class="u-flex u-m-b-4">
        <span>设备编号：{{ orderDetail.deviceCode || "--" }}</span>
        <span class="device-type u-m-l-8">
          {{ ["", "娃娃机", "兑币机", "游艺机"][orderDetail.deviceType] }}
        </span>
      </div>
      <p class="u-tips-color u-font-13 u-line-height-17">
        设备名称: {{ orderDetail.deviceName || "--" }}
      </p>
    </div>
    <!-- 商品信息 -->
    <div class="bg-white u-p-12 u-m-t-12 u-flex u-col-top">
      <div class="order-image u-m-r-10">
        <van-image width="72" height="72" radius="4" :src="coinImg" />
      </div>
      <div class="goods-info u-flex-1">
        <p class="u-line-height-18 u-flex u-row-between">
          <span>{{ orderDetail.goodsPackageName }}</span>
          <van-icon
            class-prefix="iconfont"
            name="question"
            size="18"
            color="#666666"
            @click="isShowTipsPop = true"
          />
        </p>
        <p class="u-m-t-8 u-flex u-flex-wrap">
          <!-- 币类型 -->
          <Tag
            class="u-m-r-10 u-m-b-6"
            :text="['', '购币', '储币'][orderDetail.tradeType]"
            bg="#f0f2f5"
            color="#999999"
          />
          <!-- 支付状态 -->
          <Tag
            class="u-m-r-10 u-m-b-6"
            :text="payStatusFmt(orderDetail.orderState)"
            :bg="
              orderDetail.orderState === 'PAY_SUCCESS'
                ? '#f0f2f5'
                : 'rgba(245, 108, 108, 0.1)'
            "
            :color="
              orderDetail.orderState === 'PAY_SUCCESS' ? '#999999' : '#f56c6c'
            "
          />
          <!-- 出货状态 -->
          <Tag
            v-if="orderDetail.tradeType != 2"
            class="u-m-r-10 u-m-b-6"
            :text="shipmentFmt(orderDetail.shipmenState)"
            :bg="
              orderDetail.shipmenState === 'FALL'
                ? '#f0f2f5'
                : 'rgba(245, 108, 108, 0.1)'
            "
            :color="orderDetail.shipmenState === 'FALL' ? '#999999' : '#f56c6c'"
          />
          <!-- 已出币 -->
          <Tag
            v-if="orderDetail.tradeType != 2"
            class="u-m-r-10 u-m-b-6"
            :text="`已出${
              orderDetail.fallCoinNum == null
                ? '未知个'
                : orderDetail.fallCoinNum
            }币`"
            bg="#f0f2f5"
            color="#999999"
          />
          <!-- 故障码（出货失败才显示） -->
          <Tag
            v-if="
              orderDetail.shipmenState === 'NOT_FALL' &&
              orderDetail.shipmenCode != '0'
            "
            class="u-m-r-10 u-m-b-6"
            :text="`${orderDetail.shipmenCode || ''}故障`"
            bg="rgba(245, 108, 108, 0.1)"
            color="#f56c6c"
          />
        </p>
        <div class="u-flex u-row-between u-m-t-16">
          <span class="u-line-height-20">
            ￥{{ amountFmt(orderDetail.mchAmount) }}
          </span>
          <van-button
            v-if="
              orderDetail.mainPayType !== '现金' &&
              orderDetail.orderState === 'PAY_SUCCESS' &&
              !orderDetail.refundAmount
            "
            type="default"
            round
            class="refund-btn"
            @click="handleToAddRefund"
          >
            退款
          </van-button>
          <span v-if="orderDetail.refundAmount" class="u-line-height-20">
            退款金额：￥{{ amountFmt(orderDetail.refundAmount) }}
          </span>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="bg-white u-p-12 u-m-t-12">
      <p class="u-line-height-20 u-m-b-10">订单信息</p>
      <div
        v-for="info in OrderKeys"
        :key="info.key"
        class="u-flex u-col-top info-item u-row-between"
      >
        <span class="u-font-14 u-line-height-22 u-tips-color item-label">
          {{ info.label }}
        </span>
        <div class="u-font-14 u-line-height-22 u-flex-1">
          {{
            info.format
              ? info.format(orderDetail[info.key])
              : orderDetail[info.key] || "--"
          }}
        </div>
        <span
          v-if="info.key === 'tradeNo'"
          class="u-m-l-10 copy u-line-height-22"
          @click="handleCopy"
        >
          <van-icon
            class-prefix="iconfont"
            name="fuzhi"
            color="#999999"
            size="18"
          />
        </span>
      </div>
    </div>
    <!-- 提示 -->
    <van-popup
      v-model:show="isShowTipsPop"
      round
      :lock-scroll="true"
      :close-on-popstate="true"
      teleport="body"
      position="center"
      close-on-click-overlay
      :style="{ width: '87%' }"
    >
      <div class="u-p-24">
        <p class="u-font-20 u-p-b-12 u-text-center">说明</p>

        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          1、只有天鹿主板支持显示出币数
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          2、111故障：消息超时未出币自动退款
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          3、112故障：出币失败
        </p>
      </div>
    </van-popup>
  </Page>
</template>

<script>
import { defineComponent, onActivated, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { OrderKeys } from "../const";
import { useCopy } from "@/hooks/useCopy";
import { useActions, useGetters } from "@/hooks/useVuex";
import { payStatusFmt, amountFmt, shipmentFmt } from "@/utils/format";
import Tag from "@/components/Tag.vue";
export default defineComponent({
  name: "OrderDetail",
  components: { Tag },
  setup() {
    const isShowTipsPop = ref(false);
    const route = useRoute();
    const router = useRouter();
    const tradeNo = ref("");
    const { getMchOrderDetailAction } = useActions("order", [
      "getMchOrderDetailAction",
    ]);
    const { orderDetail } = useGetters("order", ["orderDetail"]);
    onActivated(() => {
      tradeNo.value = route.query.tradeNo;
      getMchOrderDetailAction({
        tradeNo: tradeNo.value,
      });
    });
    const handleCopy = () => {
      useCopy(tradeNo.value);
    };
    const handleToAddRefund = () => {
      router.push({
        path: "/refund/add",
        query: {
          mchAmount: amountFmt(orderDetail.value.mchAmount),
          orderId: orderDetail.value.id,
          payConfigId: orderDetail.value.payConfigId,
          mchId: orderDetail.value.mchId,
        },
      });
    };
    return {
      isShowTipsPop,
      orderDetail,
      coinImg: require("@/assets/images/ic_coin.png"),
      OrderKeys,
      amountFmt,
      shipmentFmt,
      handleCopy,
      payStatusFmt,
      handleToAddRefund,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-item {
  padding: 12px 0;
  .item-label {
    display: inline-block;
    width: 100px;
  }
  .copy {
    display: inline-block;
    width: 26px;
    text-align: right;
  }
}
.refund-btn {
  width: 88px;
  height: 32px;
  font-size: 14px;
}
</style>
