<template>
  <Page
    :scroll="scrollTop"
    scroll-behavior
    is-header
    @scroll="(top) => (scrollTop = top)"
  >
    <van-sticky offset-top="1.174rem">
      <!-- 日期选择 -->
      <TimePick @change="handleTimeChange" />
      <!-- 搜索框 -->
      <Search
        v-model="tradeNo"
        placeholder="请输入订单号搜索"
        :show-action="true"
        @on-search="handleSearch"
      >
        <template #action>
          <i
            class="iconfont iconfont-shaixuan"
            @click="isShowPopRight = true"
          />
        </template>
      </Search>
    </van-sticky>
    <div class="order-list-box content">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getOrderList"
      >
        <template v-if="orderList.length">
          <template v-for="order in orderList" :key="order.tradeNo">
            <OrderItem
              :order-info="order"
              @click="handleToOrderDetail(order)"
            />
          </template>
        </template>
        <template v-else>
          <van-empty image="search" description="" />
        </template>
      </van-list>
    </div>
    <!-- 筛选 -->
    <PopRight
      v-model:visible="isShowPopRight"
      dom-key="order"
      @action="handleFilterAction"
    >
      <template #content>
        <div class="filter-box">
          <div
            v-for="(item, index) in filters"
            :key="item.label"
            class="filter-item"
          >
            <p class="filter-item-label">{{ item.label }}</p>
            <ul
              v-if="item.type === 'tag'"
              class="filter-item-value u-flex u-flex-wrap"
            >
              <li
                v-for="tag in item.value"
                :key="tag.value"
                class="filter-item-tag u-flex u-row-center"
                :class="{ selected: tag.select }"
                @click="handleSelectTag(index, tag)"
              >
                {{ tag.label }}
              </li>
            </ul>
            <div v-if="item.type === 'input'" class="filter-item-value">
              <Search
                v-model="item.searchText"
                placeholder="请输入设备编号/名称搜索"
              />
            </div>
          </div>
        </div>
      </template>
    </PopRight>
  </Page>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  toRefs,
  onActivated,
} from "vue";
import TimePick from "@/components/TimePick";
import Search from "@/components/Search";
import PopRight from "@/components/PopRight";
import OrderItem from "./components/OrderInfo.vue";
import { options } from "./const";
import { useRoute, onBeforeRouteLeave, useRouter } from "vue-router";
import { getOrderListByPageApi } from "@/api/order";
import moment from "moment";
export default defineComponent({
  name: "OrderList",
  components: {
    TimePick,
    Search,
    PopRight,
    OrderItem,
  },
  setup() {
    const scrollTop = ref(0);
    const route = useRoute();
    const router = useRouter();
    const isShowPopRight = ref(false);
    const isRender = ref(true);
    const now = moment(new Date()).format("YYYY-MM-DD");
    const currentDate = ref(now);
    const currentPage = ref(1); // 记录当前去详情的页数
    const currentTradeNo = ref(""); // 记录当前去详情的订单号
    const state = reactive({
      page: 1,
      totalSize: 0,
      loading: false,
      finished: true,
    });
    const orderList = ref([]);

    // 筛选条件
    const filters = reactive(options);
    onBeforeRouteLeave((to) => {
      if (to.name === "OrderDetail") {
        isRender.value = false;
      } else {
        isRender.value = true;
      }
    });
    // 回显参数
    onActivated(() => {
      console.log("route", route);
      if (isRender.value) {
        // 参数回显
        tradeNo.value = "";
        filters.forEach((i) => {
          if (i.type === "input") {
            i[i.key] = route.query[i.key];
          }
        });
        handleSearch();
      } else {
        getOrderList(true);
      }
    });
    const tradeNo = ref("");

    // 请求列表接口
    const getOrderList = async (isAnchor) => {
      console.log("getOrderListgetOrderList");
      try {
        const {
          code,
          content: { currentList, totalSize },
        } = await getOrderListByPageApi({
          tradeNo: tradeNo.value,
          beginTime: currentDate.value + " 00:00:00",
          endTime: currentDate.value + " 23:59:59",
          page: isAnchor ? currentPage.value : state.page,
          pageSize: 10,
          ...filterQuery.value,
        });
        if (code === 200) {
          // console.log("content", content);
          if (isAnchor) {
            // 详情返回刷新数据
            const current = currentList.find(
              (o) => o.tradeNo === currentTradeNo.value
            );
            console.log("current", current);
            if (current) {
              const currentIndex = orderList.value.findIndex(
                (i) => i.tradeNo === current.tradeNo
              );
              orderList.value[currentIndex] = current;
            }
          } else {
            if (state.page === 1) {
              orderList.value = currentList;
            } else {
              orderList.value = orderList.value.concat(currentList);
            }
            state.finished = Math.ceil(totalSize / 10) <= state.page;
            state.page++;
            state.loading = false;
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const handleSearch = () => {
      console.log(123123);
      scrollTop.value = 0;
      state.page = 1;
      getOrderList();
    };
    const handleTimeChange = (time) => {
      console.log("当前时间", time);
      scrollTop.value = 0;
      currentDate.value = time;
      state.page = 1;
      getOrderList();
    };
    // 选择条件
    const handleSelectTag = (idx, tag) => {
      filters[idx].value?.forEach((v) => {
        if (v.value === tag.value) {
          v.select = true;
        } else {
          v.select = false;
        }
      });
    };
    // 重置
    const reset = async () => {
      filters.forEach((item) => {
        if (item.type === "tag") {
          item.value.forEach((v, i) => {
            if (i) {
              v.select = false;
            } else {
              v.select = true;
            }
          });
        } else if (item.type === "input") {
          item[item.key] = "";
        }
      });
      return Promise.resolve(true);
    };
    // 计算筛选条件
    const filterQuery = computed(() => {
      let result = {};
      filters.forEach((item) => {
        if (item.type === "tag") {
          result[item.key] = item.value.find((tag) => tag.select).value;
        } else if (item.type === "input") {
          result[item.key] = item[item.key];
        }
      });
      return result;
    });
    // 操作按钮
    const handleFilterAction = async (type) => {
      if (type < 0) {
        // 重置
        await reset();
        handleSearch();
      } else {
        // 确定
        handleSearch();
      }
      isShowPopRight.value = false;
    };
    const handleToOrderDetail = (row) => {
      currentPage.value = state.page - 1;
      currentTradeNo.value = row.tradeNo;
      router.push({
        path: "/order/detail",
        query: {
          tradeNo: row.tradeNo,
        },
      });
    };
    return {
      scrollTop,
      ...toRefs(state),
      orderList,
      isShowPopRight,
      tradeNo,
      currentPage,
      currentTradeNo,
      filters,
      filterQuery,
      handleSearch,
      handleTimeChange,
      handleSelectTag,
      handleFilterAction,
      getOrderList,
      handleToOrderDetail,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-item-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
}
.filter-item-value {
  padding-bottom: 12px;
  :deep(.van-search) {
    padding: 0;
  }
}
.filter-item-tag {
  min-width: 74px;
  height: 32px;
  background-color: #f0f2f5;
  border-radius: 4px;
  color: #373838;
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding: 0 6px;
  box-sizing: border-box;
}
.selected {
  background-color: rgba(2, 122, 255, 0.2);
  color: #027aff;
}
.order-list-box {
  max-height: calc(100vh - 148px);
}
</style>
