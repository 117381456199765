import { payStatusFmt } from "@/utils/format";
export const options = [
  {
    label: "支付状态",
    type: "tag",
    key: "orderState",
    value: [
      {
        label: "全部",
        value: "",
        select: true,
      },
      {
        label: "待支付",
        value: "NO_PAY",
        select: false,
      },
      {
        label: "支付成功",
        value: "PAY_SUCCESS",
        select: false,
      },
      {
        label: "自动退款",
        value: "AUTO_REFUND",
        select: false,
      },
      {
        label: "人工退款",
        value: "SYS_REFUND",
        select: false,
      },
      {
        label: "部分退款",
        value: "PART_REFUND",
        select: false,
      },
    ],
  },
  {
    label: "支付方式",
    type: "tag",
    key: "mainPayType",
    value: [
      {
        label: "全部",
        value: "",
        select: true,
      },
      {
        label: "支付宝",
        value: "支付宝",
        select: false,
      },
      {
        label: "微信",
        value: "微信",
        select: false,
      },
      {
        label: "现金",
        value: "现金",
        select: false,
      },
    ],
  },
  {
    label: "设备编号",
    type: "input",
    key: "searchText",
    searchText: "",
  },
  {
    label: "设备类型",
    type: "tag",
    key: "deviceType",
    value: [
      {
        label: "全部",
        value: "",
        select: true,
      },
      {
        label: "娃娃机",
        value: "1",
        select: false,
      },
      {
        label: "兑币机",
        value: "2",
        select: false,
      },
      {
        label: "游艺机",
        value: "3",
        select: false,
      },
    ],
  },
];

export const OrderKeys = [
  {
    label: "支付方式",
    key: "mainPayType",
    format: null,
  },
  {
    label: "支付时间",
    key: "gmtCreate",
    format: null,
  },
  {
    label: "订单状态",
    key: "orderState",
    format: payStatusFmt,
  },
  {
    label: "消费者标识",
    key: "userId",
    format: null,
  },
  {
    label: "订单号",
    key: "tradeNo",
    format: null,
  },
  {
    label: "外部订单号",
    key: "outTradeNo",
    format: null,
  },
];

export const CoinType = [
  { label: "储币", value: 1 },
  { label: "购币", value: 2 },
];
