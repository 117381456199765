<template>
  <Page is-header :title="detailGoodsId ? '商品详情' : '新增商品'">
    <van-form ref="goodsForm" validate-first>
      <div class="bg-white">
        <van-cell-group>
          <van-field
            v-model="goodsInfo.skuPicUrl"
            label="商品图片"
            label-width="100"
            class="van-none-p"
            name="skuPicUrl"
            label-class="van-field-title"
          >
            <template #input>
              <van-uploader
                v-model="fileList"
                max-count="1"
                :after-read="afterRead"
                :deletable="detailGoodsId && changeStatus"
                :readonly="!!detailGoodsId && !changeStatus"
              />
              <!-- ImagePrefix + goodsInfo.skuPicUrl -->
            </template></van-field
          >
          <van-field
            v-if="!!detailGoodsId"
            v-model="goodsInfo.skuId"
            label="商品ID"
            placeholder="请输入商品ID"
            :rules="[{ required: true, message: '请输入商品ID' }]"
            :disabled="changeStatus"
            readonly
            label-width="100"
            class="van-none-p"
            label-class="van-field-title"
          />

          <van-field
            v-model.trim="goodsInfo.skuName"
            label="显示名称"
            placeholder="请输入显示名称"
            maxlength="10"
            :readonly="!!detailGoodsId && !changeStatus"
            :rules="[
              {
                pattern: patterns,
                message: '名称2-10个字，支持中英文数字及其组合',
              },
            ]"
            name="skuName"
            label-width="100"
            class="van-none-p"
            label-class="van-field-title"
          />
          <van-field
            v-model="goodsTypeStr"
            label="商品品类"
            placeholder="请输入商品品类"
            :rules="[{ required: true, message: '请输入商品品类' }]"
            label-width="100"
            class="van-none-p"
            name="goodsTypeStr"
            label-class="van-field-title"
            is-link
            readonly
            @click="clickShowPicker"
          />
          <van-field
            v-model="goodsInfo.skuOriginalPrice"
            label="成本价(元)"
            type="number"
            placeholder="请输入成本价"
            :rules="[{ required: true, message: '请输入成本价' }]"
            label-width="100"
            class="van-none-p"
            :readonly="!!detailGoodsId && !changeStatus"
            :formatter="formatterFixed3"
            name="skuOriginalPrice"
            label-class="van-field-title"
          />
          <van-field
            v-model="goodsInfo.skuSellingPrice"
            type="number"
            label="销售价(元)"
            placeholder="请输入销售价"
            :rules="[{ required: true, message: '请输入销售价' }]"
            label-width="100"
            class="van-none-p"
            :readonly="!!detailGoodsId && !changeStatus"
            name="skuSellingPrice"
            label-class="van-field-title"
            :formatter="formatterFixed2"
          />
        </van-cell-group>
      </div>
      <div
        v-if="useAuth('B030202')"
        class="detail-bottom u-flex bg-white u-p-12"
      >
        <van-button
          v-if="!changeStatus && detailGoodsId"
          round
          type="primary"
          plain
          @click="handleDelete"
          >删除</van-button
        >
        <van-button
          v-if="!changeStatus && detailGoodsId"
          round
          type="primary"
          class="u-m-l-12"
          @click="handleChangeStatus"
          >修改</van-button
        >
        <van-button
          v-if="changeStatus && detailGoodsId"
          round
          type="primary"
          plain
          @click="handleChangeStatus"
          >取消</van-button
        >
        <van-button
          v-if="changeStatus"
          round
          type="primary"
          native-type="submit"
          class="u-m-l-12"
          :loading="loading"
          @click="handleSubmit"
        >
          保存
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        :columns-field-names="customFieldName"
        @confirm="handleType"
        @cancel="showPicker = false"
      />
    </van-popup>
  </Page>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onActivated,
  inject,
  onDeactivated,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Dialog, Toast } from "vant";
import { uploadGoodsImg } from "@/api/app";
import { useActions, useMutations, useState } from "@/hooks/useVuex";
import { formatterFixed3, formatterFixed2 } from "@/utils/format";
export default defineComponent({
  name: "GoodsDetail",
  inject: ["useAuth"],
  setup(props, context) {
    console.log(props, context);
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      goodsInfo: {
        skuId: "",
        skuName: "",
        skuType: "",
        skuOriginalPrice: "",
        skuSellingPrice: "",
        skuPicUrl: "GOODS61779848AB8001F913B5073F.jpg",
      },

      columns: [
        {
          id: 1,
          label: "虚拟币",
        },
        {
          id: 2,
          label: "礼品",
        },
      ],
    });
    const fileList = ref([]);
    const skuPicUrl = ref("skuPicUrl");
    const ImagePrefix = inject("ImagePrefix");
    const goodsForm = ref(null);
    const customFieldName = {
      text: "label",
    };
    const patterns = /^[a-zA-Z0-9\u4E00-\u9FA5]{2,10}$/;
    const detailGoodsId = ref("");
    const showPicker = ref(false);
    const changeStatus = ref(false);
    const goodsTypeStr = ref("");
    const loading = ref(false);
    const { updateIndex } = useState("goods", ["updateIndex"]);
    const { addGoodsAction, deleteGoodsActions } = useActions("goods", [
      "addGoodsAction",
      "deleteGoodsActions",
    ]);
    const { SET_UPDATEVALUE, DELETE_GOODS } = useMutations("goods", [
      "SET_UPDATEVALUE",
      "DELETE_GOODS",
    ]);
    // mount
    onActivated(() => {
      reset();
      const params = JSON.parse(route.query.params);
      changeStatus.value = false;
      initDetail(params);
      if (!params.skuId) {
        changeStatus.value = true;
      }
    });
    onDeactivated(() => {
      showPicker.value = false;
    });
    const initDetail = (params) => {
      detailGoodsId.value = params.skuId;
      if (params.skuId) {
        let skuOriginalPrice = params.skuOriginalPrice;
        let skuSellingPrice = params.skuSellingPrice;
        delete params.skuOriginalPrice;
        delete params.skuSellingPrice;
        state.goodsInfo = {
          ...state.goodsInfo,
          ...params,
          skuType: Number(params.skuType),
          skuOriginalPrice: (skuOriginalPrice / 1000).toFixed(3),
          skuSellingPrice: (skuSellingPrice / 100).toFixed(2),
        };
        fileList.value = [
          {
            url: ImagePrefix + params.skuPicUrl,
            status: "done",
            message: "上传成功",
          },
        ];
        goodsTypeStr.value = state.columns.filter(
          (item) => item.id == params.skuType
        )[0].label;
      }
    };
    const reset = () => {
      state.goodsInfo = {
        skuId: "",
        skuName: "",
        skuType: "",
        skuOriginalPrice: "",
        skuSellingPrice: "",
        skuPicUrl: "",
      };
      goodsTypeStr.value = "";
      detailGoodsId.value = "";
      state.goodsImg = [];
      fileList.value = [];
    };
    const handleChangeStatus = () => {
      changeStatus.value = !changeStatus.value;
      const params = JSON.parse(route.query.params);
      initDetail(params);
    };

    // 删除
    const handleDelete = () => {
      Dialog.confirm({
        message: "确认删除？",
        confirmButtonColor: "#027AFF",
      }).then(async () => {
        // on close
        const { code } = await deleteGoodsActions({
          skuId: detailGoodsId.value,
        });
        if (code === 200) {
          Toast("删除成功");
          if (updateIndex !== -1) {
            DELETE_GOODS(updateIndex.value);
          }
          setTimeout(() => {
            router.back();
          }, 300);
        }
      });
    };
    // 保存
    const handleSubmit = async () => {
      try {
        if (loading.value) return;
        loading.value = true;
        if (!state.goodsInfo.skuPicUrl) {
          loading.value = false;
          Toast("请上传商品图片");
          return;
        }
        if (
          Number(state.goodsInfo.skuOriginalPrice) >
          Number(state.goodsInfo.skuSellingPrice)
        ) {
          Toast("成本价不能大于销售价");
          loading.value = false;
          return;
        }

        const res = await goodsForm.value.validate([
          "skuName",
          "goodsTypeStr",
          "skuOriginalPrice",
          "skuSellingPrice",
        ]);
        console.log(res);
        if (!state.goodsInfo.skuId) {
          delete state.goodsInfo.skuId;
        }
        const goodsInfo = { ...state.goodsInfo };
        const skuOriginalPriceBD = goodsInfo.skuOriginalPrice;
        const skuSellingPriceBD = goodsInfo.skuSellingPrice;
        delete goodsInfo.skuOriginalPrice;
        delete goodsInfo.skuSellingPrice;
        const { code } = await addGoodsAction({
          ...goodsInfo,
          skuOriginalPriceBD: skuOriginalPriceBD,
          skuSellingPriceBD: skuSellingPriceBD,
        });
        if (code === 200) {
          Toast("保存成功");
          // 详情如果是编辑的时候，需要
          if (state.goodsInfo.skuId) {
            SET_UPDATEVALUE({
              ...state.goodsInfo,
              skuOriginalPrice: state.goodsInfo.skuOriginalPrice * 1000,
              skuSellingPrice: state.goodsInfo.skuSellingPrice * 100,
            });
          }
          setTimeout(() => {
            loading.value = false;

            router.back();
          }, 300);
        }
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };
    const afterRead = async (file) => {
      file.status = "uploading";
      try {
        var params = new FormData();
        params.append("businessType", "GOODS");
        params.append("file", file.file);
        const {
          code,
          content: { url },
        } = await uploadGoodsImg(params);
        if (code === 200) {
          console.log(ImagePrefix + url);
          state.goodsInfo.skuPicUrl = url;
          skuPicUrl.value = url;
          file.status = "done";
          file.message = "上传成功";
        }
      } catch (error) {
        console.log(error);
        file.status = "failed";
        file.message = "上传失败";
      }
    };
    const handleType = ({ label, id }) => {
      state.goodsInfo.skuType = id;
      goodsTypeStr.value = label;
      showPicker.value = false;
    };
    const clickShowPicker = () => {
      if (!detailGoodsId.value || (detailGoodsId.value && changeStatus.value)) {
        showPicker.value = true;
      }
    };

    return {
      ...toRefs(state),
      detailGoodsId,
      changeStatus,
      customFieldName,
      goodsTypeStr,
      goodsForm,
      ImagePrefix,
      patterns,
      loading,
      showPicker,
      fileList,
      // event
      handleChangeStatus,
      handleSubmit,
      handleDelete,
      afterRead,
      handleType,
      clickShowPicker,
      formatterFixed3,
      formatterFixed2,
    };
  },
});
</script>

<style lang="scss" scoped>
.goods-info {
  height: 124px;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;
  justify-content: space-between;
  .img-content {
    height: 72px;
    width: 72px;
    background: #f2f2f2;
    border-radius: 4px;
  }
}

.detail-bottom {
  height: 68px;
  width: 100%;
  position: fixed;
  bottom: 0;
}
.goods-uploader {
  width: 72px;
  height: 72px;
  background: #f2f2f2;
  color: #d9d9d9;
  font-size: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.img-url {
  width: 72px;
  height: 72px;
}
</style>
