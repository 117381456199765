<template>
  <Page
    is-header
    :scroll="scrollTop"
    :scroll-behavior="true"
    @scroll="(top) => (scrollTop = top)"
  >
    <template #right>
      <div v-if="useAuth('B030202')" @click="handleAdd({ skuId: '' }, -1)">
        新增+
      </div>
    </template>
    <van-sticky offset-top="1.174rem">
      <Search v-model="skuName" @onSearch="onSearch" />
    </van-sticky>
    <div class="goods-list u-p-l-12 u-p-r-12 u-m-t-12">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getGoodsList"
      >
        <template v-for="(item, index) in goodsList" :key="item.skuId">
          <GoodsItem :info="item" @click="handleAdd({ ...item }, index)" />
        </template>
      </van-list>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onActivated } from "vue";
import GoodsItem from "./components/goodsItem.vue";
import Search from "@/components/Search.vue";
import { useState, useActions, useMutations } from "@/hooks/useVuex";
import { Toast } from "vant";
import { useRouter, onBeforeRouteLeave } from "vue-router";
// import { useRoute } from "vue-router";

export default defineComponent({
  name: "GoodsList",
  inject: ["useAuth"],
  components: {
    GoodsItem,
    Search,
  },
  setup() {
    const router = useRouter();
    const { SET_UPDATEINDEX } = useMutations("goods", ["SET_UPDATEINDEX"]);
    const { goodsListAction } = useActions("goods", ["goodsListAction"]);
    const { goodsList, totalSize, updateIndex } = useState("goods", [
      "goodsList",
      "totalSize",
      "updateIndex",
    ]);
    const scrollTop = ref(0);
    const skuName = ref("");
    const page = ref(0);
    const finished = ref(false);
    const loading = ref(false);
    const isRender = ref(false);
    onBeforeRouteLeave((to) => {
      if (to.name === "GoodsDetail" && updateIndex.value !== -1) {
        isRender.value = false;
      } else {
        isRender.value = true;
      }
    });
    onActivated(() => {
      if (isRender.value) {
        console.log("isActivated");
        skuName.value = "";
        onSearch();
      }
    });
    const getList = async () => {
      try {
        await goodsListAction({
          page: page.value,
          pageSize: 10,
          skuName: skuName.value,
        });
        finished.value = Math.ceil(totalSize.value / 10) <= page.value;
        loading.value = false;
        console.log(finished.value);
      } catch (error) {
        Toast(error.message);
      }
    };
    const onSearch = (params) => {
      scrollTop.value = 0;
      skuName.value = params;
      page.value = 1;
      getList();
    };
    const handleAdd = (params, index) => {
      SET_UPDATEINDEX(index);
      router.push({
        name: "GoodsDetail",
        query: {
          params: JSON.stringify(params),
        },
      });
    };
    const getGoodsList = () => {
      if (finished.value) return;
      page.value++;
      getList();
    };
    return {
      finished,
      loading,
      skuName,
      goodsList,
      scrollTop,
      // event
      onSearch,
      handleAdd,
      getGoodsList,
    };
  },
});
</script>

<style lang="scss" scoped></style>
