<template>
  <Page is-header>
    <template v-slot:right>
      <span v-if="useAuth('B0206')" class="u-font-14" @click="handleToConfig">
        配置
      </span>
    </template>
    <div>
      <template v-for="item in NoticeLists" :key="item.value">
        <NoticeItem
          v-model="item.checked"
          :type="2"
          :info="item"
          @update:model-value="(value) => handleChangeNotice(item, value)"
        />
      </template>
    </div>
  </Page>
</template>

<script>
import { defineComponent, onActivated, ref, inject, computed } from "vue";
import NoticeItem from "./components/NoticeItem.vue";
import { NoticeList } from "./enums";
import { cloneDeep } from "lodash";
import { useRouter } from "vue-router";
import {
  getMonitorConfigByUserApi,
  setMonitorConfigByUserApi,
} from "@/api/monitor";
import { Toast } from "vant";
import { getOpenId } from "@/hooks/useLocalStorage";
export default defineComponent({
  name: "Notice",
  components: { NoticeItem },
  setup() {
    const useAuth = inject("useAuth");
    const router = useRouter();
    const NoticeLists = ref(cloneDeep(NoticeList));
    onActivated(async () => {
      // 获取配置信息
      handleGetConfig();
    });
    const checkedMonitor = computed(() => {
      return NoticeLists.value
        .filter((item) => item.checked)
        .map((k) => k.value);
    });

    const handleGetConfig = async () => {
      try {
        const { content, code } = await getMonitorConfigByUserApi();
        if (code === 200) {
          // 筛选出要显示的
          NoticeLists.value = NoticeLists.value.filter(
            (item) =>
              Array.isArray(content.display) &&
              content.display.includes(item.value)
          );
          // 回显已显示的
          NoticeLists.value.forEach((item) => {
            item.checked =
              Array.isArray(content.content) &&
              content.content.includes(item.value);
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const handleChangeNotice = async (item, value) => {
      console.log("item, value", item, value);
      try {
        const { code } = await setMonitorConfigByUserApi({
          content: checkedMonitor.value,
          openId: getOpenId(),
        });
        if (code === 200) {
          Toast(`${value ? "开启" : "关闭"}成功`);
        }
      } catch (error) {
        console.log("error", error);
        item.checked = !value;
      }
    };

    const handleToConfig = () => {
      router.push("/notice/config");
    };
    return {
      NoticeLists,
      useAuth,
      handleChangeNotice,
      handleToConfig,
    };
  },
});
</script>
