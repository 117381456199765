<template>
  <div>
    <span :style="formatSignStyle">{{ sign }}</span>
    <span :style="formatValueStyle">{{ formatValue }}</span>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "Money",
  props: {
    value: [Number, String], // 数额
    sign: {
      type: String,
      default: "￥",
    },
    color: {
      type: String,
      default: " #FA2D19", // 颜色
    },
    shouldSign: {
      type: Boolean,
      default: true, // 是否应该展示符号
    },
    signFS: {
      type: Number,
      default: 16, // 符号的字体大小
    },
    valueFS: {
      type: Number,
      default: 16, // 数额的字体大小
    },
    toFixed: {
      type: Number,
      default: 2,
    },
    // 额外的配置，包括sign，color，保留几位，是否要增加sign=>shouldSign默认为true
  },
  setup(props) {
    const formatValue = computed(() => {
      return typeof props.value === Number
        ? props.value.toFixed(props.toFixed)
        : isNaN(Number(props.value))
        ? "0.00"
        : Number(props.value).toFixed(props.toFixed);
    });
    const formatValueStyle = computed(() => ({
      color: props.color,
      fontSize:
        typeof props.valueFS === Number ? props.valueFS + "px" : props.valueFS,
    }));
    const formatSignStyle = computed(() => ({
      color: props.color,
      fontSize:
        typeof props.signFS === Number ? props.signFS + "px" : props.signFS,
    }));
    return {
      formatValue,
      formatValueStyle,
      formatSignStyle,
    };
  },
};
</script>

<style></style>
