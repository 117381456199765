<template>
  <Page is-header>
    <div class="bg-white">
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            v-model="packageInfo.templateName"
            name="templateName"
            label="套餐名称"
            placeholder="请输入套餐名称"
            maxlength="10"
            input-align="right"
            class="van-none-p"
            label-class="van-field-title"
            :rules="[{ required: true, message: '请输入套餐名称' }]"
            error-message-align="right"
          />
          <van-field
            name="isDefault"
            label="默认套餐"
            input-align="right"
            class="van-none-p"
            label-class="van-field-title"
          >
            <template #input>
              <van-switch v-model="packageInfo.defaultValue" size="20" />
            </template>
          </van-field>
        </van-cell-group>
        <div class="u-p-l-12 u-p-r-12 u-p-b-20">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            class="submit-btn"
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </Page>
</template>

<script>
import { reactive, toRefs, onActivated } from "vue";
import { useActions } from "@/hooks/useVuex";
import { useRouter } from "vue-router";
export default {
  setup(props) {
    console.log(props);
    const router = useRouter();
    const state = reactive({
      packageInfo: { templateName: "", defaultValue: "" },
    });
    const { insertTemplateItem } = useActions("goodsPackage", [
      "insertTemplateItem",
    ]);
    onActivated(() => {
      state.packageInfo = {
        templateName: "",
        defaultValue: "",
      };
    });
    // 提交
    const onSubmit = async () => {
      try {
        const { code, content } = await insertTemplateItem({
          ...state.packageInfo,
          defaultValue: state.packageInfo.defaultValue ? 1 : 0,
        });
        if (code == 200) {
          router.replace({
            name: "PackageDetail",
            query: {
              id: content,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    return {
      ...toRefs(state),
      // event
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  margin-top: 50px;
}
</style>
