<template>
  <div class="bg-white goods-item u-p-12 u-flex">
    <div class="left-content u-flex">
      <div class="goods-img">
        <van-image
          width="72"
          height="72"
          :src="ImagePrefix + info.skuPicUrl"
          alt="商品图片"
        />
      </div>
      <div class="goods-info u-flex u-m-l-8">
        <div>{{ info.skuName }}</div>
        <div class="goods-price">
          <Money :value="(info.skuSellingPrice / 100).toFixed(2)" />
        </div>
      </div>
    </div>
    <div class="iconfont-content">
      <van-icon name="arrow-left" size="20" />
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import Money from "@/components/Money";
export default defineComponent({
  name: "GoodsItem",
  components: {
    Money,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    console.log(props);
    const ImagePrefix = inject("ImagePrefix");
    return {
      ImagePrefix,
    };
  },
});
</script>

<style scoped lang="scss">
.goods-item {
  justify-content: space-between;
  margin-bottom: 12px;

  .left-content {
    height: 100%;
    .goods-price {
      .nut-price {
        color: #fa2d19;
      }
    }
  }
  .iconfont-content {
    transform: rotate(180deg);
  }
  .goods-img {
    background: #f2f2f2;
  }
  .goods-info {
    height: 72px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}
</style>
