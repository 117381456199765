import request from "@/request/interceptors";

// 根据角色配置预警通知
export function setMonitorConfigByRoleApi(data) {
  return request({
    url: "mch/role/monitor/v1/config",
    method: "POST",
    data,
  });
}

// 根据角色查询预警配置
export function getMonitorConfigByRoleApi(params) {
  return request({
    url: "mch/role/monitor/v1/list",
    method: "GET",
    params,
  });
}

// 根据用户查询预警配置
export function getMonitorConfigByUserApi(params) {
  return request({
    url: "mch/role/monitor/v1/user/detail",
    method: "GET",
    params,
  });
}

// 根据用户配置预警通知
export function setMonitorConfigByUserApi(data) {
  return request({
    url: "mch/role/monitor/v1/user",
    method: "POST",
    data,
  });
}
